import { useState, useCallback, useEffect } from 'react';
import { useStore } from 'src/store';
import { activeModalsSelector, pushModalSelector, popModalSelector } from 'src/store/modal';

export const useModal = (initialValue = false): [boolean, () => void, () => void, () => void] => {
  const [isModalOpen, setIsModalOpen] = useState(initialValue);
  const [index, setIndex] = useState<number>(-1);
  const activeModals = useStore(activeModalsSelector);
  const pushModal = useStore(pushModalSelector);
  const popModal = useStore(popModalSelector);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
    setIndex(activeModals.length);
    pushModal({});
  }, [activeModals.length, pushModal]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    popModal();
  }, [popModal]);

  const toggle = useCallback(() => {
    if (isModalOpen) {
      return closeModal();
    }
    openModal();
  }, [closeModal, isModalOpen, openModal]);

  useEffect(() => {
    if (initialValue) {
      setIndex(activeModals.length);
      pushModal({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isModalOpen && index === activeModals.length - 1, openModal, closeModal, toggle];
};
