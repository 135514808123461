import { GetState, SetState } from 'zustand';
import type { StoreState } from './index';

type Scripts = {
  googleMaps: boolean;
};

type ScriptsKey = keyof Scripts;

export type ScriptsSlice = {
  scriptsLoaded: Scripts;
  setScriptLoaded: (scriptKey: ScriptsKey) => void;
};

const scriptsLoaded = {
  googleMaps: false,
};

export const scriptsLoadedSlice = (set: SetState<StoreState>, _get: GetState<StoreState>): ScriptsSlice => ({
  scriptsLoaded,
  setScriptLoaded: (scriptKey: ScriptsKey) => {
    set((prev) => ({ scriptsLoaded: { ...prev.scriptsLoaded, [scriptKey]: true } }));
  },
});

export const scriptsLoadedSelector = (state: StoreState) => state.scriptsLoaded;
export const isGoogleMapsLoadedSelector = (state: StoreState) => scriptsLoadedSelector(state)['googleMaps'];
export const setScriptLoadedSelector = (state: StoreState) => state.setScriptLoaded;
export const setGoogleMapsScriptLoadedSelector = (state: StoreState) => () =>
  setScriptLoadedSelector(state)('googleMaps');
