export class ApiError extends Error {
  status: number | undefined;
  code: string | number | undefined;
  error: string;
  url: string;

  constructor(message: string, code: string, url: string, status?: number) {
    super(message);
    this.error = message;
    this.code = code;
    this.url = url;
    this.status = status;
  }
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function fetcher<T>(baseUrl, endpoint: string, config: RequestInit): Promise<T> {
  return fetch(`${baseUrl}${endpoint}`, config).then(async (response) => {
    const responseText = await response.text();
    if (response.ok) {
      try {
        return (
          responseText ? (isJsonString(responseText) ? JSON.parse(responseText) : responseText) : {}
        ) as Promise<T>;
      } catch (e) {}
    }

    let errorMessage = '';
    let errorCode = '';

    if (responseText) {
      try {
        const errorValue = JSON.parse(responseText);

        console.warn(`Api Client =>`, `${baseUrl}${endpoint}`, errorValue);

        if (typeof errorValue === 'string') {
          errorMessage = errorValue;
        } else if (Array.isArray(errorValue)) {
          const [errorData] = errorValue;

          errorMessage = errorData?.message || errorData?.Message;
          errorCode = errorData?.errorCode || errorData?.ErrorCode;
        } else {
          errorMessage = errorValue?.message || errorValue?.Message || errorValue?.title || '';
        }
      } catch (e) {
        console.warn(`Api Client =>`, `${baseUrl}${endpoint}`, e);
      }
    }

    return Promise.reject(new ApiError(errorMessage, errorCode, response.url, response.status));
  });
}

type BaseClient<T = unknown> = (endpoint: string, customConfig: RequestInit) => Promise<T>;

export function buildApiClientHelpers(baseClient: BaseClient) {
  return {
    get<T>(endpoint: string, config: RequestInit = {}): Promise<T> {
      config.method = 'GET';
      return baseClient(endpoint, config) as Promise<T>;
    },
    delete<T>(endpoint: string, config: RequestInit = {}): Promise<T> {
      config.method = 'DELETE';
      return baseClient(endpoint, config) as Promise<T>;
    },
    head<T>(endpoint: string, config: RequestInit = {}): Promise<T> {
      config.method = 'HEAD';
      return baseClient(endpoint, config) as Promise<T>;
    },
    post<T>(endpoint: string, body?: any, config: RequestInit = {}): Promise<T> {
      config.method = 'POST';
      config.body = body;
      return baseClient(endpoint, config) as Promise<T>;
    },
    put<T>(endpoint: string, body?: any, config: RequestInit = {}): Promise<T> {
      config.method = 'PUT';
      config.body = body;
      return baseClient(endpoint, config) as Promise<T>;
    },
  };
}

export function buildConfig(
  { body, jsonContent = true, ...customConfig }: RequestInit,
  jwtToken?: string,
  trackingId?: string
) {
  const headers: Record<string, string> = { sourcetype: 'Consumer' };

  if (jwtToken) {
    headers.authorization = `Bearer ${jwtToken}`;
  }

  if (trackingId) {
    headers.trackingId = trackingId;
  }

  if (jsonContent) {
    headers['content-type'] = 'application/json';
  }

  const config: RequestInit = {
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = body;
    if (jsonContent) {
      config.body = JSON.stringify(body);
    }
  }

  return config;
}

export function buildApiClient(jwtToken?: string, trackingId?: string) {
  function baseClient<T>(endpoint: string, customConfig: RequestInit = {}): Promise<T> {
    const config = buildConfig(customConfig, jwtToken, trackingId);
    return fetcher<T>(process.env.NEXT_PUBLIC_API_URL, endpoint, config);
  }

  return buildApiClientHelpers(baseClient);
}

export function buildOctaneClient(jwtToken?: string, trackingId?: string) {
  function baseClient<T>(endpoint: string, customConfig: RequestInit = {}): Promise<T> {
    const config = buildConfig(customConfig, jwtToken, trackingId);
    return fetcher<T>(process.env.NEXT_PUBLIC_OCTANE_URL, endpoint, config);
  }

  return buildApiClientHelpers(baseClient);
}
