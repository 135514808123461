import { buildApiClientHelpers, fetcher, buildConfig } from './build-api-client';

let token: string | undefined;
let trackingId: string | undefined;

export const apiClient = buildApiClientHelpers(baseClient);

export type ApiClient = ReturnType<typeof buildApiClientHelpers>;

function baseClient<T>(endpoint: string, customConfig: RequestInit = {}): Promise<T> {
  const config = buildConfig(customConfig, token, trackingId);
  return fetcher<T>(process.env.NEXT_PUBLIC_API_URL, endpoint, config);
}

export function setJwtToken(jwtToken: string) {
  token = jwtToken;
}

export function getJwtToken() {
  return token;
}

export function clearJwtToken() {
  token = undefined;
}

export function setTrackingId(value: string) {
  trackingId = value;
}
