import { AddressDto } from 'src/api/octane/models.homes';

export const GA_ACTIONS = {
  ADDRESS_ENTERED_CALCULATOR: 'AddressEnteredCalculator',
  ADDRESS_ENTERED_LANDING_PAGE_COVER: 'AddressEnteredLandingPageCover',
  ADDRESS_ENTERED_SIGNUP_FLOW: 'AddressEnteredSignUpFlow',
  LEAD_CREATED_CALCULATOR: 'LeadCreatedCalculator',
  LEAD_CREATED_SIGNUP_FLOW: 'LeadCreatedSignUpFlow',
  LEAD_CREATED_TRY_THE_PROS: 'LeadCreatedTryThePros',
  LEAD_CREATED_LEASING: 'LeadCreatedLeasing',
  LEAD_CREATED_RENT_ESTIMATE: 'LeadCreatedRentEstimate',
};

const ACTIONS_MAP = {
  LeadCreatedCalculator: 'calculator',
  LeadCreatedSignUpFlow: 'sign_up_flow',
  LeadCreatedTryThePros: 'ask_a_question',
  LeadCreatedLeasing: 'leasing',
  LeadCreatedRentEstimate: 'rent_estimate',
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (window.gtag) {
    window.gtag('config', process.env.NEXT_PUBLIC_GA_ID, {
      page_path: url,
    });
  }
};

type GaEvent = {
  action: string;
  category?: string;
  label?: string;
  value?: any;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GaEvent) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};

type EnhancedConversionUser = {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  zipcode?: string;
  country?: string;
};

export const setEnhancedConvertionData = (user: EnhancedConversionUser) => {
  const enhancedConversionUser: any = {};
  if (user.email) enhancedConversionUser.email = user.email;
  if (user.phone) enhancedConversionUser.phone_number = user.phone;
  if (user.firstName) enhancedConversionUser.first_name = user.firstName;
  if (user.lastName) enhancedConversionUser.last_name = user.lastName;
  if (user.zipcode) enhancedConversionUser.postal_code = user.zipcode;
  if (user.country) enhancedConversionUser.country = user.country;

  window.dataLayer?.push?.(enhancedConversionUser);
};

export const trackHomeownerAddressEntered = (action: any, address: any, rent?: any) => {
  if (!action || !address?.city) {
    return;
  }

  const addressEnteredEvent: any = {
    event_category: 'Homeowner',
    event_label: `Rent|${address.city.replace(/ /g, '')}-${address.state.toUpperCase()}|${address.zipcode}`,
  };

  if (rent?.estimate) {
    addressEnteredEvent.value = parseInt(rent.estimate, 10);
  }

  if (window.gtag) {
    window.gtag('event', action, addressEnteredEvent);
  }
};

export const trackHomeownerLeadCreated = ({
  action,
  address,
  rent,
  coverageType,
  region,
}: {
  action: any;
  address: any;
  rent: any;
  coverageType?: string;
  region?: string;
}) => {
  if (!action) {
    return;
  }

  const leadCreatedEvent: any = { event_category: 'Homeowner' };
  const datadogAndMixPanelLeadEvent: any = { type: ACTIONS_MAP[action as keyof typeof ACTIONS_MAP] };
  const fullstoryLeadEvent: any = {
    type: ACTIONS_MAP[action as keyof typeof ACTIONS_MAP],
    current_url_str: window.location.href,
  };

  if (region) {
    datadogAndMixPanelLeadEvent.region = region;
    fullstoryLeadEvent.region_str = region;
  }

  if (address?.zipcode) {
    leadCreatedEvent.zipcode = address.zipcode;
    datadogAndMixPanelLeadEvent.zipcode = address.zipcode;
    fullstoryLeadEvent.zipcode_str = address.zipcode;
  }

  if (address?.streetAddress) {
    datadogAndMixPanelLeadEvent.streetAddress = address.streetAddress;
    fullstoryLeadEvent.street_address_str = address.streetAddress;
  }

  if (address?.city) {
    leadCreatedEvent.event_label = `Rent|${address.city.replace(/ /g, '')}-${address.state.toUpperCase()}|${
      address.zipcode
    }`;

    datadogAndMixPanelLeadEvent.city = address.city;
    fullstoryLeadEvent.city_str = address.city;
  }

  if (rent?.estimate) {
    leadCreatedEvent.value = parseInt(rent.estimate, 10);

    datadogAndMixPanelLeadEvent.rentEstimate = rent?.estimate;
    fullstoryLeadEvent.rent_estimate_int = parseInt(rent.estimate, 10);
  }

  if (coverageType) {
    leadCreatedEvent.coverage_type = coverageType;
    datadogAndMixPanelLeadEvent.coverage_type = coverageType;
    fullstoryLeadEvent.coverage_type_str = coverageType;
  }

  try {
    // TODO: Find a better way to structure this.
    if (window.mixpanel) {
      window.mixpanel.track('lead_created', datadogAndMixPanelLeadEvent);
    }

    if (window.DD_RUM) {
      window.DD_RUM.addAction('lead_created', datadogAndMixPanelLeadEvent);
    }

    if (window.FS) {
      window.FS.event('lead_created', fullstoryLeadEvent);
    }

    if (coverageType !== 'out_of_coverage') {
      if (window.gtag) {
        window.gtag('event', action, leadCreatedEvent);
      }

      window.dataLayer?.push({
        event: action,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export function trackHomeownerXLeadCreated(address: AddressDto, source: string) {
  const leadCreatedEvent: Record<string, string> = { source };
  const datadogAndMixPanelLeadEvent: Record<string, string> = { source };
  const fullstoryLeadEvent: Record<string, string> = { source, current_url_str: window.location.href };

  if (address?.zipcode) {
    leadCreatedEvent.zipcode = address.zipcode;
    datadogAndMixPanelLeadEvent.zipcode = address.zipcode;
    fullstoryLeadEvent.zipcode_str = address.zipcode;
  }

  if (address?.streetAddress) {
    datadogAndMixPanelLeadEvent.streetAddress = address.streetAddress;
    fullstoryLeadEvent.street_address_str = address.streetAddress;
  }

  if (address?.city) {
    leadCreatedEvent.city = address.city;
    datadogAndMixPanelLeadEvent.city = address.city;
    fullstoryLeadEvent.city_str = address.city;
  }

  try {
    if (window.mixpanel) {
      window.mixpanel.track('lead_created_x', datadogAndMixPanelLeadEvent);
    }

    if (window.DD_RUM) {
      window.DD_RUM.addAction('lead_created_x', datadogAndMixPanelLeadEvent);
    }

    if (window.FS) {
      window.FS.event('lead_created_x', fullstoryLeadEvent);
    }

    if (window.gtag) {
      window.gtag('event', 'lead_created_x', leadCreatedEvent);
    }

    window.dataLayer?.push({
      event: 'lead_created_x',
      ...leadCreatedEvent,
    });
  } catch (e) {
    console.log(e);
  }
}
