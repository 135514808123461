import Script from 'next/script';
import { COOKIES_CONFIG } from '@belong/common';
import { parseCookies } from 'nookies';

export function RedditPixel() {
  const cookies = parseCookies();
  const trackingId = cookies[COOKIES_CONFIG.TRACKING_ID.name];

  return (
    <Script id="reddit-pixel" strategy="lazyOnload">
      {`!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${process.env.NEXT_PUBLIC_REDDIT_PIXEL_ID}');rdt('track', 'PageVisit', { externalId: '${trackingId}' });`}
    </Script>
  );
}
