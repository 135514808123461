var formatFullStoryKeys = function (obj) {
  var formattedEntries = Object.entries(obj).filter(function (val) {
    return val[1] !== undefined && val[1] !== null;
  }).map(function (_a) {
    var key = _a[0],
      val = _a[1];
    var value = val;
    if (typeof val === 'boolean') {
      return ["".concat(key, "_bool"), value];
    }
    if (typeof val === 'number') try {
      value = typeof val === 'string' ? val : JSON.stringify(val);
    } catch (_b) {
      value = '';
    }
    return ["".concat(key, "_str"), val];
  });
  return Object.fromEntries(formattedEntries);
};

export { formatFullStoryKeys };
