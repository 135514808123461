import { COOKIES_CONFIG } from '@belong/common';
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { CoverageRegionModel } from 'src/api/models';
import { OTHER_REGION_NAME } from 'src/constants/address';
import { GetState, SetState } from 'zustand';
import { StateStorage } from 'zustand/middleware';
import type { StoreState } from './index';

const { REGION } = COOKIES_CONFIG;

export type RegionSlice = {
  region: CoverageRegionModel;
  pickRegion: (id: CoverageRegionModel) => void;
  noRegionPicked: () => void;
};

export const somewhereElse: CoverageRegionModel = {
  id: 0,
  name: OTHER_REGION_NAME,
  region: OTHER_REGION_NAME,
  state: 'NA' as any,
  status: 'Other',
  information: {
    contactInformation: {
      coordinates: {
        latitude: '35.774455858003854',
        longitude: '-120.08102520000001',
      },
    },
  },
};

export const regionSlice = (set: SetState<StoreState>, _: GetState<StoreState>) => ({
  region: somewhereElse,
  pickRegion: (region: CoverageRegionModel) => {
    set(() => {
      return { region };
    });
  },
  noRegionPicked: () => {
    set(() => ({ region: somewhereElse }));
  },
});

export const regionSelector = (state: StoreState) => state.region;
export const regionIdSelector = (state: StoreState) => regionSelector(state).id;
export const regionStatusSelector = (state: StoreState) => regionSelector(state).status;
export const regionCoordinatesSelector = (state: StoreState) =>
  regionSelector(state).information?.contactInformation?.coordinates;
export const pickRegionSelector = (state: StoreState) => state.pickRegion;
export const noRegionPickedSelector = (state: StoreState) => state.noRegionPicked;

export const regionCookieStorage: StateStorage = {
  getItem: (name: string) => {
    const cookies = parseCookies();
    const regionCookie = cookies[name];

    if (!regionCookie) return null;

    let parsedRegionCookie = JSON.parse(regionCookie);
    const parsedValuesRegion: Record<string, any> = {};
    try {
      Object.entries(parsedRegionCookie).forEach(([key, val]) => {
        parsedValuesRegion[key] = JSON.parse(val as string);
      });

      parsedRegionCookie = parsedValuesRegion;
    } catch {}

    return JSON.stringify({ state: { region: parsedRegionCookie } });
  },
  setItem: (name: string, value: string) => {
    const parsedValue = JSON.parse(value);
    setCookie(null, name, JSON.stringify(parsedValue.state.region), REGION.options);
  },
  removeItem: (name: string) => {
    destroyCookie(null, name);
  },
};
