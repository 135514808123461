import { COOKIES_CONFIG } from '@belong/common';
import { formatFullStoryKeys } from '@belong/common';
import { nanoid } from 'nanoid';
import { parseCookies } from 'nookies';
import { UserModel } from 'src/api/models';
import { trackSiteVisitService } from 'src/api/platform';
import { setJwtToken, setTrackingId } from 'src/utils/api-client';
import { setJwtToken as setOctaneJwtToken } from 'src/utils/octane-client';
import { getExperimentsFromCookies } from './experiments';
import { setEnhancedConvertionData } from './tracking';

const { TRACKING_ID, JWT_TOKEN } = COOKIES_CONFIG;
const mixpanelApiKey = process.env.NEXT_PUBLIC_MIXPANEL_API_KEY;

// Only run this function once on the client.
let hasRunOnce = false;

export function initClient(user?: UserModel, partnerKey?: string) {
  if (!hasRunOnce) {
    hasRunOnce = true;

    const cookies = parseCookies();
    const trackingId = cookies[TRACKING_ID.name] || nanoid();
    const jwtToken = cookies[JWT_TOKEN.name];
    const experimentsFromCookies = getExperimentsFromCookies();
    const accessibilityPreferences = {
      theme_preference_str: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
      language_preference_str: window.navigator.language,
      reduced_motion_preference_bool: window.matchMedia('(prefers-reduced-motion: reduce)').matches,
      is_high_contrast_mode_preference_bool: window.matchMedia('(forced-colors: active)').matches,
    };

    // TODO: FIgure out naming convention for multiple experiments!!
    window.FS?.event?.('session_started', {
      env_str: process.env.NEXT_PUBLIC_ENV,
      tracking_id_str: trackingId,
      ...accessibilityPreferences,
      ...formatFullStoryKeys(experimentsFromCookies),
    });

    window.mixpanel?.init(mixpanelApiKey, { persistence: 'localStorage', api_host: `${window.location.origin}/mp` });
    // TODO: FIgure out naming convention for multiple experiments!!
    window.mixpanel?.register({
      'First Visited URL': window.location.href,
      'First Visited Path': window.location.pathname,
      ...accessibilityPreferences,
      ...experimentsFromCookies,
    });

    if (user) {
      if (user.userId) {
        window.mixpanel?.identify?.(user.userId);
        window.mixpanel?.register?.({
          email: user.email,
          ...accessibilityPreferences,
        });

        window.FS?.identify?.(user.userId, {
          email: user.email,
          ...accessibilityPreferences,
        });
      }

      setEnhancedConvertionData(user);
    }

    setJwtToken(jwtToken);
    setOctaneJwtToken(jwtToken);
    setTrackingId(trackingId);
    try {
      trackSiteVisitService(trackingId, partnerKey, experimentsFromCookies);
    } catch (e) {}
  }
}
