import Head from 'next/head';
import Script from 'next/script';

export function GoogleAnalytics({ gaId, ga4Id }: Props): JSX.Element {
  return (
    <>
      {/* Global site tag (gtag.js) - Google Analytics */}
      <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`} />
      {/* We want this to be loaded synchronously before first render, that's why it's inside Head and not script */}
      <Head>
        <script
          type="application/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){
              dataLayer.push(arguments);
            }
            gtag('js', new Date());                      
            gtag('config', '${gaId}');
            gtag('config', '${ga4Id}');`,
          }}
        />
        {/* Calling confirm twice as detailed on the dual tagging docs:
        https://support.google.com/analytics/answer/9310895#gtagjs-enable-basic&zippy=%2Cin-this-article */}
      </Head>
    </>
  );
}

type Props = {
  gaId: string;
  ga4Id: string;
};
