import { SetState } from 'zustand';
import { StoreState } from './index';

export type ChatSlice = {
  isChatActive: boolean;
  activateChat: () => void;
  deactivateChat: () => void;
};

export const chatSlice = (set: SetState<StoreState>) => ({
  activateChat: () => {
    set(() => ({ isChatActive: true }));
  },
  deactivateChat: () => {
    set(() => ({ isChatActive: false }));
  },
});

export const isActiveChatSelector = (state: StoreState) => state.isChatActive;
export const activateChatSelector = (state: StoreState) => state.activateChat;
export const deactivateChatSelector = (state: StoreState) => state.deactivateChat;
