import { useLayoutEffect } from 'react';
import { COOKIES_CONFIG } from '@belong/common';
import { SetState, GetState } from 'zustand';
import create from 'zustand';
import createContext from 'zustand/context';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import { calculatorSlice } from './calculator';
import type { CalculatorSlice } from './calculator';
import type { ChatSlice } from './chat';
import { chatSlice } from './chat';
import { listingSlice } from './listings';
import type { ListingSlice } from './listings';
import { activeModalSlice } from './modal';
import type { ActiveModalSlice } from './modal';
import { regionSlice, regionCookieStorage } from './region';
import type { RegionSlice } from './region';
import { scriptsLoadedSlice } from './scripts';
import type { ScriptsSlice } from './scripts';

// Union of states here, example: StoreState = ActiveModalSlice & SomeOtherNewSlice
export type StoreState = ActiveModalSlice & RegionSlice & ScriptsSlice & CalculatorSlice & ListingSlice & ChatSlice;
export let store: any;

export const { Provider, useStore } = createContext<StoreState>();

// At this time there is no documentation on how to persist multiple slices, so using "any" for now on the StoreApiWithPersist.
export const initializeStore = (preloadedState = {}) => {
  return create<StoreState, SetState<StoreState>, GetState<StoreState>, StoreApiWithPersist<any>>((set, get, api) => {
    return {
      ...activeModalSlice(set, get),
      ...scriptsLoadedSlice(set, get),
      ...calculatorSlice(set, get),
      ...listingSlice(set),
      ...chatSlice(set),
      // regionSlice
      ...persist((_set, _get) => ({ ...regionSlice(_set as SetState<StoreState>, _get as GetState<StoreState>) }), {
        name: COOKIES_CONFIG.REGION.name,
        getStorage: () => regionCookieStorage,
        partialize: (state) => ({ region: state.region }),
      })(set as any, get, api),
      ...preloadedState,
    };
  });
};

export function useCreateStore(initialState: StoreState) {
  // For SSR & SSG, always use a new store.
  if (typeof window === 'undefined') {
    return () => initializeStore(initialState);
  }

  // For CSR, always re-use same store.
  store = store ?? initializeStore(initialState);
  // And if initialState changes, then merge states in the next render cycle.
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    if (initialState && store) {
      store.setState({
        ...store.getState(),
        ...initialState,
      });
    }
  }, [initialState]);

  return () => store;
}
