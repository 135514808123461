export const NProgress = (() => {
  let Settings = {
    minimum: 0.08,
    easing: 'linear',
    speed: 200,
    trickleSpeed: 200,
    barSelector: '[role="bar"]',
    template: `
      <div class="bar" role="bar">
        <div class="peg"></div>
      </div>`,
  };

  let status = null;

  const clamp = (value, min, max) => Math.max(min, Math.min(max, value));

  const toBarPerc = (value) => (-1 + value) * 100;

  const barPositionCSS = (value, speed, ease) => ({
    transform: `translate3d(${toBarPerc(value)}%,0,0)`,
    transition: `all ${speed}ms ${ease}`,
  });

  const css = (element, properties, value) => {
    if (typeof properties === 'object') {
      for (let prop in properties) {
        if (properties.hasOwnProperty(prop)) {
          const name = prop.replace(/^-ms-/, 'ms-').replace(/-([\da-z])/gi, (match, letter) => letter.toUpperCase());
          element.style[name] = properties[prop];
        }
      }
    } else {
      element.style[prop] = value;
    }
  };

  const queue = (() => {
    const pending = [];

    const next = () => {
      const fn = pending.shift();
      if (fn) {
        fn(next);
      }
    };

    return (fn) => {
      pending.push(fn);
      if (pending.length === 1) next();
    };
  })();

  return {
    get settings() {
      return Settings;
    },

    configure(options) {
      Settings = { ...Settings, ...options };
      return this;
    },

    get status() {
      return status;
    },

    set(value) {
      const started = this.isStarted();

      value = clamp(value, Settings.minimum, 1);
      status = value === 1 ? null : value;

      const progress = this.render(!started),
        bar = progress.querySelector(Settings.barSelector),
        { speed, easing } = Settings;

      progress.offsetWidth;

      queue((next) => {
        css(bar, barPositionCSS(value, speed, easing));

        if (value === 1) {
          css(progress, {
            transition: 'none',
            opacity: 1,
          });

          progress.offsetWidth;

          setTimeout(() => {
            css(progress, {
              transition: `all ${speed}ms linear`,
              opacity: 0,
            });

            setTimeout(() => {
              this.remove();
              next();
            }, speed);
          }, speed);
        } else {
          setTimeout(next, speed);
        }
      });

      return this;
    },

    isStarted() {
      return typeof status === 'number';
    },

    start() {
      if (!status) this.set(0);

      const work = () => {
        setTimeout(() => {
          if (!status) return;
          this.inc();
          work();
        }, Settings.trickleSpeed);
      };

      work();

      return this;
    },

    done(force) {
      if (!force && !status) return this;

      return this.inc(0.3 + 0.5 * Math.random()).set(1);
    },

    inc(amount) {
      if (!status) {
        return this.start();
      } else if (status > 1) {
        return;
      } else {
        if (typeof amount !== 'number') {
          amount = [0.1, 0.04, 0.02, 0.005, 0][Math.floor(status * 5)];
        }

        status = clamp(status + amount, 0, 0.994);
        return this.set(status);
      }
    },

    render(fromStart) {
      if (this.isRendered()) return document.getElementById('nprogress');

      const progress = document.createElement('div');
      progress.id = 'nprogress';
      progress.innerHTML = Settings.template;

      const bar = progress.querySelector(Settings.barSelector),
        perc = fromStart ? '-100' : toBarPerc(status || 0);

      css(bar, {
        transition: 'all 0 linear',
        transform: `translate3d(${perc}%,0,0)`,
      });

      document.querySelector('body').appendChild(progress);
      return progress;
    },

    remove() {
      const progress = document.getElementById('nprogress');
      progress?.parentNode?.removeChild(progress);
    },

    isRendered() {
      return !!document.getElementById('nprogress');
    },
  };
})();
