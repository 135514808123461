import { AddressRequestModel } from 'src/api/models';
import { GetState, SetState } from 'zustand';
import type { StoreState } from './index';

export type Hoa = { hasHoa: false } | { hasHoa: true; hoaFee: number };

export type Mortgage = { hasMortgage: false } | { hasMortgage: true; mortgage: number };

export type PriceEstimate = {
  estimate: number;
  highRange: number;
  lowRange: number;
};

export type RentEstimate = {
  estimate: number;
  highRange: number;
  lowRange: number;
};

export type CalculatorAdvanced = {
  yearBuilt?: string;
  lastMajorRenovation?: string;
  ageOfAppliances?: string;
  currentState?: string;
  lookingForTenant?: boolean;
  selectedPlan?: string;
};

export type SliderEstimate = {
  rentEstimate: number;
  percentage: number;
};

type Calculator = {
  address?: AddressRequestModel;
  priceEstimate?: PriceEstimate;
  rentEstimate?: RentEstimate;
  hoa?: Hoa;
  mortgage?: Mortgage;
  currentPropertyId?: string;
  currentHomeId?: string;
  sliderEstimate?: SliderEstimate;
  advanced: CalculatorAdvanced;
};

type CalculatorKey = keyof Calculator;

export type CalculatorSlice = {
  calculator: Calculator;
  setValue: (calculatorKey: CalculatorKey) => (value: any) => void;
};

const calculator = {
  advanced: {},
};

export const calculatorSlice = (set: SetState<StoreState>, _: GetState<StoreState>): CalculatorSlice => ({
  calculator,
  setValue: (calculatorKey: CalculatorKey) => (value: any) => {
    set((prev) => ({ calculator: { ...prev.calculator, [calculatorKey]: value } }));
  },
});

export const calculatorSelector = (state: StoreState) => state.calculator;
export const addressSelector = (state: StoreState) => calculatorSelector(state)['address'];
export const priceEstimateSelector = (state: StoreState) => calculatorSelector(state)['priceEstimate'];
export const rentEstimateSelector = (state: StoreState) => calculatorSelector(state)['rentEstimate'];
export const hoaSelector = (state: StoreState) => calculatorSelector(state)['hoa'];
export const mortgageSelector = (state: StoreState) => calculatorSelector(state)['mortgage'];
export const currentPropertyIdSelector = (state: StoreState) => calculatorSelector(state)['currentPropertyId'];
export const currentHomeIdSelector = (state: StoreState) => calculatorSelector(state)['currentHomeId'];
export const sliderEstimateSelector = (state: StoreState) => calculatorSelector(state)['sliderEstimate'];
export const advancedSelector = (state: StoreState) => calculatorSelector(state)['advanced'];

export const setCalculatorValueSelector = (state: StoreState) => state.setValue;
export const setAddressSelector = (state: StoreState) => setCalculatorValueSelector(state)('address');
export const setPriceEstimateSelector = (state: StoreState) => setCalculatorValueSelector(state)('priceEstimate');
export const setRentEstimateSelector = (state: StoreState) => setCalculatorValueSelector(state)('rentEstimate');
export const setMortgageSelector = (state: StoreState) => setCalculatorValueSelector(state)('mortgage');
export const setHoaSelector = (state: StoreState) => setCalculatorValueSelector(state)('hoa');
export const setCurrentPropertyIdSelector = (state: StoreState) =>
  setCalculatorValueSelector(state)('currentPropertyId');
export const setCurrentHomeIdSelector = (state: StoreState) => setCalculatorValueSelector(state)('currentHomeId');
export const setSliderEstimateSelector = (state: StoreState) => setCalculatorValueSelector(state)('sliderEstimate');
export const setAdvancedSelector = (state: StoreState) => setCalculatorValueSelector(state)('advanced');
