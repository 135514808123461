import { useEffect, useRef, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { ContactUsModalProps } from 'src/components/contact-us-modal/contact-us-modal';
import { SupportHelpBridgeModalProps } from 'src/components/header/support-help-bridge-modal/support-help-bridge-modal';
import type { Props as EnterContactModalProps } from 'src/features/auth/enter-contact-modal/enter-contact-modal';
import { HomeownersCalculatorProps } from 'src/features/homeowners-calculator/homeowners-calculator';
import type { Props as InPersonVisitsFlowModalProps } from 'src/features/in-person-visits/components/in-person-visits-flow-modal/in-person-visits-flow-modal';
import { AskAQuestionProps } from 'src/modals/ask-a-question/ask-a-question';
import { HowItWorksProps } from 'src/modals/how-it-works/how-it-works';
import { NotThereYetProps } from 'src/modals/not-there-yet/not-there-yet';
import { SmoothPaymentsProps } from 'src/modals/smooth-payments/smooth-payments';
import { useStore } from 'src/store';
import {
  activeModalsSelector,
  pushModalSelector,
  popModalSelector,
  clearModalsSelector,
  replaceModalSelector,
} from 'src/store/modal';
import { getStringParam } from 'src/utils/params';
import { LoginModalRouterProps } from '../login-modal-router/login-modal-router';
import { PaymentMethodModalProps } from '../payment-methods-modal/payment-methods-modal';

const AskAQuestion = dynamic<AskAQuestionProps>(() =>
  import('../ask-a-question/ask-a-question').then((mod) => mod.AskAQuestion)
);

const ContactUsModal = dynamic<ContactUsModalProps>(() =>
  import('src/components/contact-us-modal/contact-us-modal').then((mod) => mod.ContactUsModal)
);

const HomeownersCalculator = dynamic<HomeownersCalculatorProps>(() =>
  import('src/features/homeowners-calculator/homeowners-calculator').then((mod) => mod.HomeownersCalculator)
);

const HowItWorks = dynamic<HowItWorksProps>(() => import('../how-it-works/how-it-works').then((mod) => mod.HowItWorks));

const LoginModalRouter = dynamic<LoginModalRouterProps>(() =>
  import('../login-modal-router/login-modal-router').then((mod) => mod.LoginModalRouter)
);

const SmoothPayments = dynamic<SmoothPaymentsProps>(() =>
  import('../smooth-payments/smooth-payments').then((mod) => mod.SmoothPayments)
);

const NotThereYet = dynamic<NotThereYetProps>(() =>
  import('../not-there-yet/not-there-yet').then((mod) => mod.NotThereYet)
);

const InPersonVisitsFlowModal = dynamic<InPersonVisitsFlowModalProps>(() =>
  import('src/features/in-person-visits/components/in-person-visits-flow-modal/in-person-visits-flow-modal').then(
    (module) => module.InPersonVisitsFlowModal
  )
);

const EnterContactModal = dynamic<EnterContactModalProps>(() =>
  import('src/features/auth/enter-contact-modal/enter-contact-modal').then((module) => module.EnterContactModal)
);

const PaymentMethodsModal = dynamic<PaymentMethodModalProps>(() =>
  import('../payment-methods-modal/payment-methods-modal').then((module) => module.PaymentMethodModal)
);

const SupportHelpBridgeModal = dynamic<SupportHelpBridgeModalProps>(() =>
  import('../../components/header/support-help-bridge-modal/support-help-bridge-modal').then(
    (module) => module.SupportHelpBridgeModal
  )
);

export const modalTypes = [
  'ASK_A_QUESTION',
  'CONTACT_US',
  'HOW_IT_WORKS',
  'LOGIN',
  'NAPKIN_MODAL',
  'SMOOTH_PAYMENTS',
  'NOT_THERE_YET',
  'IN_PERSON_VISITS_FLOW_MODAL',
  'ENTER_CONTACT',
  'PAYMENT_METHODS',
  'HELP_BRIDGE',
] as const;

export type ModalType = typeof modalTypes[number];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MODAL_MAP: Record<ModalType, any> = {
  ASK_A_QUESTION: AskAQuestion,
  CONTACT_US: ContactUsModal,
  HOW_IT_WORKS: HowItWorks,
  LOGIN: LoginModalRouter,
  NAPKIN_MODAL: HomeownersCalculator,
  SMOOTH_PAYMENTS: SmoothPayments,
  NOT_THERE_YET: NotThereYet,
  IN_PERSON_VISITS_FLOW_MODAL: InPersonVisitsFlowModal,
  ENTER_CONTACT: EnterContactModal,
  PAYMENT_METHODS: PaymentMethodsModal,
  HELP_BRIDGE: SupportHelpBridgeModal,
};

export function ModalManager() {
  const { pathname, asPath, push, query } = useRouter();
  const { activeModal: _activeModalFromQuery = '', ...modalProps } = query;
  let activeModalFromQuery = getStringParam(_activeModalFromQuery);

  // Handle scenario of email clients changing LOGIN&currentScreen=reset_password_screen to LOGIN¤tScreen=reset_password_screen
  if (activeModalFromQuery.startsWith('LOGIN') && activeModalFromQuery.includes('tScreen=')) {
    // Replace activeModalFromQuery with LOGIN and set currentScreen to the value after tScreen=
    const currentScreen = activeModalFromQuery.split('=')[1];
    modalProps.currentScreen = currentScreen;
    activeModalFromQuery = `LOGIN`;
  }

  const activeModals = useStore(activeModalsSelector);
  const pushModal = useStore(pushModalSelector);
  const popModal = useStore(popModalSelector);
  const clearModals = useStore(clearModalsSelector);
  const replaceModal = useStore(replaceModalSelector);

  const handleDismiss = useCallback(() => {
    // If there was an activeModalFromQuery and handleDismiss is called, clear query params
    const path = new URL(asPath, 'http://n').pathname;

    if (typeof activeModalFromQuery !== 'undefined' && activeModalFromQuery !== '') {
      push(path, path, { shallow: true });
    } else {
      popModal();
    }
  }, [activeModalFromQuery, asPath, popModal, push]);

  const lastActiveModalFromQuery = useRef<ModalType | undefined>(undefined);

  useEffect(() => {
    if (isModalType(activeModalFromQuery) && lastActiveModalFromQuery.current !== activeModalFromQuery) {
      // If there was no lastActiveModalFromQuery push it, otherwise replace it, only one allowed in the modal queue.
      if (!lastActiveModalFromQuery.current) {
        pushModal({ type: activeModalFromQuery, props: modalProps });
      } else {
        replaceModal({ type: activeModalFromQuery, props: modalProps });
      }

      lastActiveModalFromQuery.current = activeModalFromQuery;
    } else if (isModalType(lastActiveModalFromQuery.current) && typeof activeModalFromQuery === 'undefined') {
      handleDismiss();
      lastActiveModalFromQuery.current = activeModalFromQuery;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModalFromQuery]);

  const lastPathname = useRef<string>(pathname);
  useEffect(() => {
    if (!activeModalFromQuery && pathname !== lastPathname.current) {
      clearModals();
    }
  }, [activeModalFromQuery, pathname, clearModals]);

  const activeOpenModals = activeModals.map((modal, index) => ({
    ...modal,
    isOpen: index === activeModals.length - 1,
  }));

  const activeManagerModals = activeOpenModals.filter((modal) => {
    return isModalType(modal.type) && MODAL_MAP[modal.type];
  });

  const previousActiveManagerModals = useRef<typeof activeManagerModals>(activeManagerModals);
  useEffect(() => {
    if (activeManagerModals.length !== previousActiveManagerModals.current.length) {
      previousActiveManagerModals.current = activeManagerModals;
      if (activeManagerModals.length === 0) {
        push(asPath, asPath, { shallow: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeManagerModals.length]);

  // Returning between fragment so typescript doesn't complain.
  return (
    <>
      {activeManagerModals.map((modal) => {
        const modalType = modal?.type && isModalType(modal.type) && modal.type;
        const ModalComponent = modalType && MODAL_MAP[modalType];
        return ModalComponent ? <ModalComponent key={modalType} onDismiss={handleDismiss} {...modal.props} /> : null;
      })}
    </>
  );
}

function isModalType(string: unknown): string is ModalType {
  return typeof string === 'string' && modalTypes.includes(string as ModalType);
}
