import { apiClient } from 'src/utils/api-client';
import { PayoutPlanVariationModel, SettingsModel } from './models';

export function fetchPayoutPlansService() {
  return apiClient.get<PayoutPlanVariationModel[]>(`/payout-plans`);
}

export function fetchSettingsService() {
  return apiClient.get<SettingsModel>(`/settings`);
}

// We use the url to track UTM in the params.
export function trackSiteVisitService(trackingId: string, partnerKey?: string, experiments?: Record<string, string>) {
  return apiClient.post<void>(
    '/sitevisit',
    {
      trackingId,
      url: window.location.href,
      referrer: document.referrer,
      encodedPartnerKey: partnerKey,
      experiments,
    },
    {
      keepalive: true,
    }
  );
}
