import { useRouter } from 'next/router';
import { AnalyticsProvider } from '@belong/common';
import { useUser, useUserClaims } from 'src/features/users/useUser';
import { initClient } from 'src/utils/init-client';
import { usePayoutPlans } from './index';

export function ClientInit({ children }: Props) {
  const { asPath } = useRouter();

  // router.query is always empty on the first render, so we need to use the asPath
  const url = new URL(asPath, 'https://belonghome.com'); // 2nd argument doesn't really matter, we just care about params.
  const partnerKey = url.searchParams.get('gspk') || undefined;

  const { user } = useUser();
  // TODO: Apparently not needed for anything yet.
  // useSettings();
  usePayoutPlans();
  const { userId } = user || {};

  const { userClaims } = useUserClaims({ enabled: !!userId });

  if (typeof window === 'undefined') return <>{children}</>;
  initClient(user, partnerKey);

  return (
    <AnalyticsProvider userId={userId} userClaims={userClaims} projectName="next">
      {children}
    </AnalyticsProvider>
  );
}

type Props = {
  children: React.ReactNode;
};
