import { SetState } from 'zustand';
import { StoreState } from './index';

export type ListingSlice = {
  hoveredListingId: string | null;
  setHoveredListing: (hoveredListingId: string) => void;
  clearHoveredListing: () => void;
};

export const listingSlice = (set: SetState<StoreState>) => ({
  hoveredListingId: null,
  setHoveredListing: (hoveredListingId: string) => set(() => ({ hoveredListingId })),
  clearHoveredListing: () => set(() => ({ hoveredListingId: null })),
});

export const hoveredListingIdSelector = (state: StoreState) => state.hoveredListingId;
export const setHoveredListing = (state: StoreState) => state.setHoveredListing;
export const clearHoveredListing = (state: StoreState) => state.clearHoveredListing;
