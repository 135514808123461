export const OFFICE_ADDRESS: Record<string, string> = {
  'Bay Area': '<span>100 S Ellsworth Ave Ste 400,</span> <span>San Mateo, CA 94401</span>',
  'Los Angeles': '<span>815 Moraga Drive,</span> <span>Los Angeles, CA 90049</span>',
  'Orange County': '<span>2211 Michelson Drive,</span> <span>Irvine, CA 92612</span>',
  // TODO: MISSING SD AND SEATTLE
  // TODO: Fix Miami Address
  Miami: '<span>100 S Ellsworth Ave Ste 400,</span> <span>San Mateo, CA 94401</span>',
};

export type StateName = 'ca' | 'wa' | 'fl';

export type RegionStatus = 'Active' | 'ComingSoon' | 'Other';

export type Region = {
  name: string;
  slug: string;
  shortName: string;
  status: RegionStatus;
  id: number;
  lat: number;
  lng: number;
  zoom: number;
};

export const REGION_TYPES: Record<string, Region> = {
  ALL: {
    name: 'All Cities',
    slug: '',
    shortName: '',
    status: 'Active',
    id: 0,
    lat: 35.774455858003854,
    lng: -120.08102520000001,
    zoom: 6,
  },
  BAY_AREA: {
    name: 'Bay Area',
    slug: 'bay-area',
    shortName: 'Bay Area',
    status: 'Active',
    id: 1,
    lat: 37.617869178994134,
    lng: -122.14861549999998,
    zoom: 10,
  },
  LOS_ANGELES: {
    name: 'LA Area',
    slug: 'los-angeles',
    shortName: 'LA',
    status: 'Active',
    id: 2,
    lat: 33.87247697628222,
    lng: -118.20228505,
    zoom: 10,
  },
  ORANGE_COUNTY: {
    name: 'Orange County',
    slug: 'orange-county',
    shortName: 'OC',
    status: 'Active',
    id: 3,
    lat: 33.727283,
    lng: -117.8691637,
    zoom: 10,
  },
  SAN_DIEGO: {
    name: 'San Diego Area',
    slug: 'san-diego',
    shortName: 'San Diego',
    status: 'Active',
    id: 4,
    lat: 32.8242404,
    lng: -117.3891576,
    zoom: 10,
  },
  SEATTLE: {
    name: 'Seattle Area',
    slug: 'seattle',
    shortName: 'Seattle',
    status: 'Active',
    id: 5,
    lat: 47.6129432,
    lng: -122.4821456,
    zoom: 10,
  },
  MIAMI: {
    name: 'Miami',
    slug: 'miami',
    shortName: 'Miami',
    status: 'Active',
    id: 6,
    lat: 25.761681,
    lng: -80.191788,
    zoom: 10,
  },
};
export const OTHER_REGION_NAME = 'Nationally';

export const REGIONS: Region[] = [
  REGION_TYPES.BAY_AREA,
  REGION_TYPES.LOS_ANGELES,
  REGION_TYPES.MIAMI,
  REGION_TYPES.ORANGE_COUNTY,
  REGION_TYPES.SAN_DIEGO,
  REGION_TYPES.SEATTLE,
];

export const getRegionById = (id: number) => {
  if (REGIONS.length > id - 1) {
    return REGIONS[id - 1];
  }
  return null;
};

export const INVALID_ZIPCODE = '00000';
