import { useEffect, useState } from 'react';
import Script from 'next/script';
import { useUser } from '../users/useUser';

const clientToken = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_CLIENT_TOKEN;
const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_APPLICATION_ID;
const buildNumber = process.env.NEXT_PUBLIC_BUILD_NUMBER;
const apiUrl = process.env.NEXT_PUBLIC_API_URL;
const env = process.env.NEXT_PUBLIC_ENV;

export function DataDog() {
  const [isDDRumScriptLoaded, setIsDDRumScriptLoaded] = useState(false);
  const [isDDLogsScriptLoaded, setDDLogsIsScriptLoaded] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    if (isDDRumScriptLoaded && isDDLogsScriptLoaded && user?.userId) {
      window.DD_RUM.setUser({
        id: user.userId,
      });

      window.DD_LOGS?.setGlobalContextProperty('user', user.userId);
    }
  }, [isDDRumScriptLoaded, isDDLogsScriptLoaded, user?.userId]);

  if (env === 'development') {
    return null;
  }

  return (
    <>
      <Script
        id="datadog"
        strategy="lazyOnload"
        src="https://www.datadoghq-browser-agent.com/datadog-rum-v4.js"
        onLoad={() => {
          window.DD_RUM?.onReady(() => {
            window.DD_RUM?.init({
              clientToken,
              applicationId,
              site: 'datadoghq.com',
              service: 'belong-consumer',
              env,
              // Specify a version number to identify the deployed version of your application in Datadog
              version: buildNumber,
              sampleRate: 100,
              trackInteractions: true,
              premiumSampleRate: 100,
              allowedTracingOrigins: [apiUrl],
              silentMultipleInit: true,
            });

            setIsDDRumScriptLoaded(true);
          });
        }}
      />
      <Script
        id="datadog-logs"
        strategy="lazyOnload"
        src="https://www.datadoghq-browser-agent.com/datadog-logs-v4.js"
        onLoad={() => {
          window.DD_LOGS?.onReady(() => {
            window.DD_LOGS?.init({
              clientToken,
              forwardErrorsToLogs: true,
              site: 'datadoghq.com',
              service: 'belong-consumer',
              env,
              version: buildNumber,
              sampleRate: 100,
              silentMultipleInit: true,
            });

            setDDLogsIsScriptLoaded(true);
          });
        }}
      />
    </>
  );
}
