import { ENVIRONMENTS } from '@belong/common';

const { PRODUCTION, DEV7 } = ENVIRONMENTS;

export const isProd = process.env.NEXT_PUBLIC_ENV === PRODUCTION;
export const isDev7 = process.env.NEXT_PUBLIC_ENV === DEV7;

export const isProjectPhoenixEnabledSMS = process.env.NEXT_PUBLIC_ENV !== PRODUCTION;
export const isPriceDropsEnabled = true;

export const isBelongXPlacementsEnabled = !isProd;
