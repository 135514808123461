import { ReactNode } from 'react';
import { ENVIRONMENTS } from '@belong/common';
import { PrismicPreview as PrismicPreviewComponent } from '@prismicio/next';

const { STAGING, DEVELOPMENT } = ENVIRONMENTS;
const isStaging = process.env.NEXT_PUBLIC_ENV === STAGING;
const isLocal = process.env.NEXT_PUBLIC_ENV === DEVELOPMENT;
const isLocalAndEnabled = isLocal && process.env.NEXT_PUBLIC_PRISMIC_PREVIEW_ENABLED === '1';

export function PrismicPreview({ repositoryName, children }: Props) {
  if (isStaging || isLocalAndEnabled) {
    return <PrismicPreviewComponent repositoryName={repositoryName}>{children}</PrismicPreviewComponent>;
  }

  return <>{children}</>;
}

type Props = {
  repositoryName: string;
  children: ReactNode;
};
