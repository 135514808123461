import { useQuery } from 'react-query';
import { groupRegionByState, groupActiveRegionByState } from '@belong/common';
import type { State } from '@belong/common';
import { PayoutPlanVariationModel } from 'src/api/models';
import { fetchPayoutPlansService, fetchSettingsService } from 'src/api/platform';
import { fetchRegionsService } from 'src/api/region';

export function useSettings() {
  const { isLoading, data, error } = useQuery('settings', fetchSettingsService);
  return { isSettingsLoading: isLoading, settings: data, settingsError: error };
}

type PayoutRegionId = Exclude<PayoutPlanVariationModel['regionId'], undefined>;
type PayoutName = Exclude<PayoutPlanVariationModel['name'], undefined>;
type PayoutPlan = Exclude<PayoutPlanVariationModel['plan'], undefined>;
export type PayoutPlansNormalizedByIdAndName = Record<
  PayoutRegionId,
  Record<PayoutName, Record<PayoutPlan, PayoutPlanVariationModel>>
>;

export function usePayoutPlans() {
  const { isLoading, data, error } = useQuery('payout-plans', fetchPayoutPlansService, {
    select: (payoutPlans: PayoutPlanVariationModel[]): PayoutPlansNormalizedByIdAndName => {
      // Group array by region Id -> name -> plan.
      const payoutPlansNormalizedByIdAndName: PayoutPlansNormalizedByIdAndName = {};
      payoutPlans.forEach((payoutPlan) => {
        if (!payoutPlan.regionId || !payoutPlan.name || !payoutPlan.plan) return;

        if (!payoutPlansNormalizedByIdAndName[payoutPlan.regionId]) {
          payoutPlansNormalizedByIdAndName[payoutPlan.regionId] = {};
        }

        if (!payoutPlansNormalizedByIdAndName[payoutPlan.regionId][payoutPlan.name]) {
          payoutPlansNormalizedByIdAndName[payoutPlan.regionId][payoutPlan.name] = {
            Monthly: {},
            NoGuarantee: {},
            Yearly: {},
          };
        }

        payoutPlansNormalizedByIdAndName[payoutPlan.regionId][payoutPlan.name][payoutPlan.plan] = payoutPlan;
      });

      return payoutPlansNormalizedByIdAndName;
    },
  });

  return { isPayoutPlansLoading: isLoading, payoutPlans: data, payoutPlansError: error };
}

export function useRegions() {
  const { isLoading, data = [], error } = useQuery('regions', () => fetchRegionsService());
  const dataWithoutOtherRegions = data?.filter((region) => region.status !== 'Other') || [];

  const regionsByState = groupRegionByState(dataWithoutOtherRegions) || {};
  const activeRegionsByState = groupActiveRegionByState(dataWithoutOtherRegions) || {};
  const states = Object.keys(regionsByState).sort((a, b) => a.localeCompare(b)) as State['code'][];
  const activeStates = Object.keys(activeRegionsByState).sort((a, b) => a.localeCompare(b));

  return {
    isRegionsLoading: isLoading,
    regions: dataWithoutOtherRegions,
    regionsByState,
    activeRegionsByState,
    states,
    activeStates,
    regionsError: error,
  };
}
