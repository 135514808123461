import type { ModalType } from 'src/modals/modal-manager/modal-manager';
import { GetState, SetState } from 'zustand';
import type { StoreState } from './index';

export type ActiveModal =
  | {
      type: ModalType;
      props?: any;
    }
  | Record<string, unknown>;

export type ActiveModalSlice = {
  activeModals: ActiveModal[];
  pushModal: (modal: ActiveModal) => void;
  popModal: () => void;
  clearModals: () => void;
  replaceModal: (modal: ActiveModal) => void;
};

const activeModals: ActiveModal[] = [];

export const activeModalSlice = (set: SetState<StoreState>, _: GetState<StoreState>) => ({
  activeModals,
  pushModal: (modal: ActiveModal) => {
    set((prev) => ({ activeModals: prev.activeModals.concat(modal) }));
  },
  popModal: () => {
    set((prev) => {
      const lastIndex = prev.activeModals.length - 1;
      return { activeModals: prev.activeModals.slice(0, lastIndex) };
    });
  },
  clearModals: () => {
    set(() => ({ activeModals: [] }));
  },
  replaceModal: (modal: ActiveModal) => {
    set((prev) => {
      const lastIndex = prev.activeModals.length - 1;
      return { activeModals: prev.activeModals.slice(0, lastIndex).concat(modal) };
    });
  },
});

export const activeModalsSelector = (state: StoreState) => state.activeModals;
export const pushModalSelector = (state: StoreState) => state.pushModal;
export const popModalSelector = (state: StoreState) => state.popModal;
export const clearModalsSelector = (state: StoreState) => state.clearModals;
export const replaceModalSelector = (state: StoreState) => state.replaceModal;
