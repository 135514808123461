import { GetServerSidePropsContext, NextPageContext } from 'next';
import { COOKIES_CONFIG } from '@belong/common';
import { parseCookies } from 'nookies';

const { EXPERIMENTS } = COOKIES_CONFIG;

export function getExperimentsFromCookies(ctx?: NextPageContext | GetServerSidePropsContext) {
  const cookies = parseCookies(ctx);

  const existingExperiments = cookies[EXPERIMENTS.name];
  let parsedExperiments: Record<string, string> = {};
  try {
    parsedExperiments = JSON.parse(existingExperiments);
  } catch {}

  return parsedExperiments;
}

export function getExperimentVariantFromCookies(
  experimentName: string,
  ctx?: NextPageContext | GetServerSidePropsContext
) {
  const experiments = getExperimentsFromCookies(ctx);
  return experiments[experimentName];
}
